<template>
    <div class="controlValueDomain" style="display:inline-block;">

        <label class="label" @click="distributionLoading = true;" @keyup.enter="distributionLoading = true;"><slot></slot></label>

        <el-dialog
                :title="title"
                class="editDialog"
                :append-to-body="true"
                width="600px"
                :visible.sync="distributionLoading"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :show-close="true"
            >
            <div class="content">
                <el-form ref="form" :model="form" label-width="7em" :rules="rules">
                    <el-form-item label="字典类别" prop="dictionariesName" ref="dictionariesName">
                        <ChoiceData :configure="{request:$api['admin/dictClassify'].findPage,type:'radio'}" @choiceDataChange="choiceDataChange">
                            <el-input slot="button" :readonly="true" v-model="form.dictionariesName"></el-input>
                            <template slot="tableColumn">
                                <el-table-column align="center" prop="name" label="字典名称"></el-table-column>
                                <el-table-column prop="code" align="center" label="字典代码"></el-table-column>
                            </template>
                        </ChoiceData>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer">
                <el-button type="success" @click="save">确定</el-button>
                <el-button  type="info" plain @click="distributionLoading = false">取 消</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {
        ChoiceData: () => import("@/components/ChoiceData.vue"),
    },
    props: {
        title:{
            type:String,
            default:'控件值域'
        },
        type:{
            type:String,
            default:''
        },
        value: {
            type:String,
            default:''
        },
    },
    data() {
        return {
            distributionLoading:false,
            form: {
                dictionariesName:''
            },
            rules: {

            },
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch:{
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){console.log(newVal)
                if(newVal){
                    try{
                        this.form = JSON.parse(newVal)
                    }catch (err){
                        console.log(err)
                    };
                }
            }
        },
    },
    created() {},
    methods: {
        choiceDataChange(res){
            this.form.dictionariesCode = res.code;
            this.form.dictionariesName = res.name;
        },
        save(){
            this.$refs.form.validate((valid,obj) => {
                if (valid) {
                    this.distributionLoading = false;
                    this.$emit('change',JSON.stringify(this.form));
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";
.controlValueDomain {
    /deep/.label {
        * {
            cursor:pointer;
        }
    }
}
</style>
